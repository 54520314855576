import React, { useState } from 'react';
import { Box, Button } from "@material-ui/core";
import Title from "../Title";
import routes from '../../api/routes';
import PrimaryButton from '../PrimaryButton';
import SendIcon from '@material-ui/icons/Send';
import TextInput from '../form/TextInput';
import { MissionReportsOnRecruiterDto } from '../../dto/missionReport';
import useFetch from '../../hooks/useFetch';
import moment from 'moment';
import useAuth from '../../hooks/useAuth';
import { post } from '../../api';

const UserReportForm = ({ onClose, missionId, recruiterId }: { onClose: () => any, missionId: string, recruiterId: string }) => {
	let [, missionReports] = useFetch<MissionReportsOnRecruiterDto>(routes.missions.reports.recruiterReports({ id: missionId }, { recruiterId }), []);
	let { user } = useAuth();
	let [midForm, setMidForm] = useState('');
	let [endForm, setEndForm] = useState('');
	let [done, setDone] = useState(false);

	function submit() {
		submitMidCommentary()
		submitEndCommentary()
		setDone(true);
	}

	function submitMidCommentary() {
		if (!midForm) { return }
		post(routes.missions.reports.createOrUpdateOnRecruiter,  {
			type: 'mid',
			missionId: missionId,
			date: moment().format('YYYY-MM-DD'),
			comment: midForm,
			creatorId: user.id,
			recruiterId: recruiterId
		},)
	}

	function submitEndCommentary() {
		if (!endForm) { return }
		post(routes.missions.reports.createOrUpdateOnRecruiter,  {
			type: 'end',
			missionId: missionId,
			date: moment().format('YYYY-MM-DD'),
			comment: endForm,
			creatorId: user.id,
			recruiterId: recruiterId
		},)
	}

	return <Box style={{ width: "min(450px, 80vw)" }}>
		{done ? <Box>
			<Box mb={2}>
				<Title small color="#44BB44">Les commentaires ont bien été envoyés !</Title>
			</Box>
			<Box mt={1} display="flex" justifyContent="end">
				<PrimaryButton onClick={onClose}>Fermer</PrimaryButton>
			</Box>
		</Box> :
		<Box>
			<Title mb={2}>Commentaire de mi-mission</Title>
			<TextInput fullWidth multiline rows={5}  label={missionReports?.mid ? "Commentaire actuel: '" + missionReports.mid.comment + "'" : 'Pas de commentaire'} value={midForm} onChange={(str) => setMidForm(str)}/>
			<Title mb={2}>Commentaire de fin de mission</Title>
			<TextInput fullWidth multiline rows={5}  label={missionReports?.end ? "Commentaire actuel: '" + missionReports.end.comment + "'" : 'Pas de commentaire'} value={endForm} onChange={(str) => setEndForm(str)}/>
			<Box mt={1} display="flex" justifyContent="end">
				<Button onClick={() => submit()}>
					<Box mr={1.5} mt={1}> <SendIcon /> </Box>
					Envoyer
				</Button>
			</Box>
		</Box>}
	</Box>
}

export default UserReportForm;