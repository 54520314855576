import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import useFetch from '../../hooks/useFetch';
import routes from '../../api/routes';
import { Card } from '../Cards';
import { AssociationFiltersDto } from '../../dto/association';
import { AssociationStats, calculateStats } from '../../dto/associationUtils';
import { formatNumber } from '../../utils';
import Modal from '../Modal';
import AbsentDetailsModal from './AbsentDetailsModal';

const Dashboard1 = ({
	filters,
	hideDetails = false,
}: {
	filters: AssociationFiltersDto;
	hideDetails?: boolean;
}) => {
	const [, dayPartialDashboardStats] = useFetch<AssociationStats>(
		routes.associations.dashboardStats({}, filters),
		[JSON.stringify(filters)],
	);
	let dashboardStats = calculateStats((dayPartialDashboardStats || {}) as any);
	const [showAbsentDetailsModal, setShowAbsentDetailsModal] = useState(false);

	return (
		<>
			{showAbsentDetailsModal && (
				<Modal open onClose={() => setShowAbsentDetailsModal(false)}>
					<Box p={2}>
						<AbsentDetailsModal missionId={filters.missionId} recruiterId={filters.recruiterId} onClose={() => setShowAbsentDetailsModal(false)} />
					</Box>
				</Modal>
			)}
			<Card title="hr rue">
				<Typography style={{ color: '#3f51b5', fontSize: 20 }}>
					{formatNumber(dashboardStats.streetHours, 2)}h
				</Typography>
			</Card>
			<Card title="%HR/HP">
				<Typography style={{ fontSize: 20 }}>
					{formatNumber(dashboardStats.streetPaidHoursRatio, 2)}%
				</Typography>
			</Card>
			<Card title="bs réel">
				<Typography style={{ color: '#61BE7E', fontSize: 20 }}>
					{formatNumber(dashboardStats.completedDonationsCount, 2)}
				</Typography>
			</Card>
			<Card title="tx réel">
				<Typography style={{ color: '#9ACD33', fontSize: 20, margin: -2 }}>
					{formatNumber(dashboardStats.realRate, 2)}
				</Typography>
				<Typography style={{ fontSize: 14, margin: -2 }}>
					{formatNumber(dashboardStats.realRateOver25, 2)}
				</Typography>
			</Card>
			<Card title="age median">
				<Typography style={{ color: '#EE814F', fontSize: 20, margin: -2}}>
					{formatNumber(dashboardStats.medianAge, 2)} ans
				</Typography>
			</Card>
			<Card title="don moyen">
				<Typography style={{ color: '#ba5ae2', fontSize: 20, margin: -2}}>
					{formatNumber(dashboardStats.averageDonation, 2)}€
				</Typography>
				<Typography style={{ fontSize: 14, margin: -2}}>
					{formatNumber(dashboardStats.medianDonation, 2)}
				</Typography>
			</Card>
			<Card title="%stop pa">
				<Typography style={{ color: '#FF0000', fontSize: 20, margin: -2 }}>
					{formatNumber(dashboardStats.averageStopPA, 2)}%
				</Typography>
				<Typography style={{ fontSize: 14, margin: -2}}>
					{formatNumber(dashboardStats.under25StopPA, 2)}%
				</Typography>
			</Card>
			<Card title="%18/20">
				<Typography style={{ fontSize: 18, margin: -2 }}>
					{formatNumber(dashboardStats._18_20_percent, 2)}%
				</Typography>
				<Typography style={{ fontSize: 14, margin: -2}}>
					{formatNumber(dashboardStats.between18And20Count, 2)}
				</Typography>
			</Card>
			<Card title="%+25">
				<Typography style={{ fontSize: 18, margin: -2 }}>
					{formatNumber(dashboardStats._25_plus_percent, 0)}%
				</Typography>
				<Typography style={{ fontSize: 14, margin: -2}}>
					{formatNumber(dashboardStats._25_plus_percent * dashboardStats.donationsTotalCount / 100.0, 0)}
				</Typography>
			</Card>
			<Card onClick={() => setShowAbsentDetailsModal(true)} title="absences">
				<Typography style={{ fontSize: 20 }}>
					{dashboardStats.absence}
				</Typography>
			</Card>
		</>
	);
};

export default Dashboard1;
