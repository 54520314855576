import React from 'react';
import { Box, Typography } from '@material-ui/core';
import useFetch from '../../hooks/useFetch';
import routes from '../../api/routes';
import { Card, CardContainer } from '../Cards';
import { AssociationFiltersDto } from '../../dto/association';
import { AssociationStats, calculateStats } from '../../dto/associationUtils';
import { formatNumber } from '../../utils';
import { MissionDto } from '../../dto/mission';
import Title from '../Title';
import { MissionGoalsDto } from '../../dto/missionGoals';
import { MissionUserGoalsDto } from '../../dto/missionUserGoals';

export const DashboardGoals = ({
	missionId,
	mission,
	filters,
}: {
	filters: AssociationFiltersDto;
	mission: MissionDto;
	missionId: number;
}) => {
	const [, partialStats] = useFetch<AssociationStats>(routes.associations.dashboardStats({}, filters), [
		JSON.stringify(filters),
	]);
	let dashboardStats = calculateStats((partialStats || {}) as any);

	const [, goals] = useFetch<MissionGoalsDto & { errors: any }>(
		routes.missions.goals.get({ missionId }, filters),
	);
	let proportion = calculateElaspedProportion(Date.parse(mission?.startDate), Date.parse(mission?.endDate));
	return goals?.errors ? (
		<Box></Box>
	) : (
		<Box>
			<Title mt={3}>Suivi des objectifs</Title>
			<CardContainer display="flex" mt={1}>
				{goals?.streetHours ? (
					<GoalNumber
						name="HR Rue"
						isPercent
						number={calculateDiff(dashboardStats.streetHours, goals.streetHours, proportion).percentage}
					/>
				) : (
					<></>
				)}
				{goals?.completedDonations ? (
					<GoalNumber
						name="BS Réel"
						isPercent
						number={
							calculateDiff(
								dashboardStats.completedDonationsCount,
								goals.completedDonations,
								proportion,
							).percentage
						}
					/>
				) : (
					<></>
				)}
				<GoalNumber
					name="Don moyen"
					isPercent
					number={
						calculateDiff(dashboardStats?.averageDonation, goals?.averageDonation, proportion)
							.percentage
					}
				/>
				<GoalNumber
					name="Âge médian"
					isPercent
					number={calculateDiff(dashboardStats?.medianAge, goals?.medianAge, proportion).percentage}
				/>
				<GoalNumber
					name="%Stop PA"
					isPercent
					number={
						calculateDiff(dashboardStats?.stopPAPercent, goals?.stopPAPercent, proportion).percentage
					}
				/>
			</CardContainer>
		</Box>
	);
};

export const DashboardGoalsRD = ({
	missionId,
	recruiterId,
	mission,
	filters,
}: {
	filters: AssociationFiltersDto;
	mission: MissionDto;
	missionId: number;
	recruiterId: number;
}) => {
	const [, partialStats] = useFetch<AssociationStats>(routes.associations.dashboardStats({}, filters), [
		JSON.stringify(filters),
	]);
	let dashboardStats = calculateStats((partialStats || {}) as any);
	const [, goals] = useFetch<MissionUserGoalsDto & { errors: any }>(
		routes.missions.goals.getRecruiterGoals({ missionId, recruiterId }, filters),
	);
	let proportion = calculateElaspedProportion(Date.parse(mission?.startDate), Date.parse(mission?.endDate));
	return goals?.errors ? (
		<Box></Box>
	) : (
		<Box>
			<Title mt={3}>Suivi des objectifs</Title>
			<CardContainer display="flex" mt={1}>
				<GoalNumber
					name="Taux réel"
					isPercent
					number={calculateDiff(dashboardStats?.realRate, goals?.realRate, proportion).percentage}
				/>
				<GoalNumber
					name="Don médian"
					isPercent
					number={
						calculateDiff(dashboardStats?.medianDonation, goals?.medianDonation, proportion).percentage
					}
				/>
				<GoalNumber
					name="Âge médian"
					isPercent
					number={calculateDiff(dashboardStats?.medianAge, goals?.medianAge, proportion).percentage}
				/>
				<GoalNumber
					name="%18-30"
					isPercent
					number={
						calculateDiff(dashboardStats?._18_30_percent, goals?.percentage1830, proportion).percentage
					}
				/>
				<GoalNumber
					name="%Stop PA"
					isPercent
					number={
						calculateDiff(dashboardStats?.stopPAPercent, goals?.stopPaPercent, proportion).percentage
					}
				/>
			</CardContainer>
		</Box>
	);
};

const GoalNumber = ({ name, number, isPercent }: { name: string; number: number; isPercent?: boolean }) => {
	let pos = number >= 0;
	return (
		<Card title={name}>
			<Typography style={{ color: pos ? 'green' : 'red', fontSize: 20, margin: -2 }}>
				{(pos ? '+' : '') + formatNumber(number, 2) + (isPercent ? '%' : '')}
			</Typography>
		</Card>
	);
};

function daysBetween(d1, d2): number {
	return (d2 - d1) / 1000 / 60 / 60 / 24;
}

function calculateDiff(result, target, proportion): { diff: number; percentage: number } {
	let prop_target = target * proportion;
	let diff = result - prop_target;
	let percentage = (result / prop_target) * 100 - 100;
	return { percentage, diff };
}

function calculateElaspedProportion(start, end): number {
	let length = daysBetween(start, end);
	let elapsed = Math.ceil(daysBetween(start, Date.now()));
	return Math.min(elapsed / length, 1.0);
}
