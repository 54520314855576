import { Box, Button, CircularProgress, InputLabel, Link, makeStyles, Paper } from '@material-ui/core';
import { AddCircle, Edit, RemoveCircle } from '@material-ui/icons';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import SendIcon from '@material-ui/icons/Send';
import SummarizeIcon from '@mui/icons-material/Summarize';
import RuleIcon from '@mui/icons-material/Rule';
import moment from 'moment';
import React, { useState } from 'react';
import { FaFileExcel } from 'react-icons/fa';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { del, post } from '../../api';
import routes from '../../api/routes';
import { CardContainer } from '../../components/Cards';
import FileModalPicker from '../../components/files/FilePickerModal';
import DatePicker from '../../components/form/DatePicker';
import DeleteButton from '../../components/form/DeleteButton';
import ReturnButton from '../../components/form/ReturnButton';
import AgeStats from '../../components/missions/AgeStats';
import HourlyStats from '../../components/missions/HourlyStats';
import MessageForm from '../../components/missions/MessageForm';
import MissionComments from '../../components/missions/MissionComments';
import Dashboard1 from '../../components/missions/MissionDashboard1';
import Dashboard2 from '../../components/missions/MissionDashboard2';
import { MissionOverviewChart } from '../../components/missions/MissionOverviewChart';
import MissionRecruitersTable from '../../components/missions/MissionRecruitersTable';
import Previsions from '../../components/missions/Previsions';
import Modal from '../../components/Modal';
import PrimaryButton from '../../components/PrimaryButton';
import Title from '../../components/Title';
import { AssociationFiltersDto } from '../../dto/association';
import { FileDto } from '../../dto/file';
import { MissionWithAssociationDto } from '../../dto/mission';
import { MissionReportsDto } from '../../dto/missionReport';
import { MissionUserDto } from '../../dto/user';
import useFetch from '../../hooks/useFetch';
import MissionDatas from '../../components/missions/MissionDatas';
import FilterWeeks from '../../components/FilterWeeks';
import ReportForm from '../../components/missions/ReportForm';
import { DashboardGoals } from '../../components/missions/MissionDashboardGoals';
import GoalsForm from '../../components/missions/GoalsForm';

export const sortUsersMission = (users: MissionUserDto[]) => {
	return users?.sort((a, b) => {
		if (!a.tricoId) return 1;
		if (!b.tricoId) return -1;
		if (a.position === 'RE') {
			return -1;
		} else if (b.position === 'RE') {
			return 1;
		}
		if (!a.trialPeriodEndType && b.trialPeriodEndType) {
			return -1;
		} else if (a.trialPeriodEndType && !b.trialPeriodEndType) {
			return 1;
		}
		return Number(a.tricoId.slice(1)) - Number(b.tricoId.slice(1));
	});
};

const MissionDetails = () => {
	let { id } = useParams<{ id: string }>();
	let [filePickerOpen, setFilePickerOpen] = useState(false);
	let [isLoading, mission] = useFetch<MissionWithAssociationDto>(routes.missions.get({ id: +id }));
	const [, logo] = useFetch<{ logo: string }>(routes.missions.logo({ id: +id }));
	let [authorizations, setAuthorizations] = useState<FileDto[]>([]);
	let [authsAreLoading] = useFetch<FileDto[]>(routes.missions.authorizations.all({ id }), [], (auths) =>
		setAuthorizations(auths),
	);

	const [openDashboard2, setDashboard2] = useState(false);
	const [refetchUsers] = useState(0);
	const [users, setUsers] = useState<MissionUserDto[]>([]);
	const [areUsersLoading] = useFetch<MissionUserDto[]>(
		routes.missions.users.all({ id }),
		[refetchUsers],
		(users) => setUsers(sortUsersMission(users)),
	);

	const [periodStart, setPeriodStart] = useState('');
	const [periodEnd, setPeriodEnd] = useState('');
	let filters = new AssociationFiltersDto();
	Object.assign(filters, {
		missionId: id,
		periodStart: periodStart ? moment(periodStart).format('YYYY-MM-DD 00:00:00') : '',
		periodEnd: periodEnd ? moment(periodEnd).format('YYYY-MM-DD 23:59:59') : '',
		minimumDonation: '0',
	});

	const classes = useStyles({ border: false });

	const addAuthorization = (file: FileDto) => {
		post(routes.missions.authorizations.add({ id }), { fileId: file.id });
		setAuthorizations([...authorizations, file]);
	};

	const deleteAuthorization = (fileId: string) => {
		del(routes.missions.authorizations.delete({ id }), { fileId });
		setAuthorizations(authorizations.filter((file) => +file.id !== +fileId));
	};

	const [, reports] = useFetch<MissionReportsDto>(routes.missions.reports.get({ id }), []);

	const [showMessageModal, setShowMessageModal] = useState(false);
	const [showReportModal, setShowReportModal] = useState(false);
	const [showGoalsModal, setShowGoalsModal] = useState(false);

	return (
		<Box px={4} py={0} position="relative" mb={8}>
			{isLoading ? (
				<Box display="flex" justifyContent="center" my={8}>
					<CircularProgress />
				</Box>
			) : (
				<Box style={{ position: 'relative', width: '100%' }}>
					<Box display="flex" justifyContent="space-between" flexWrap="wrap" alignItems="center">
						<ReturnButton to={`/missions`}>Retour aux missions</ReturnButton>
						<Box display="flex">
							<Box mr={1}>
								<PrimaryButton size="small" onClick={() => setShowGoalsModal(true)}>
									<RuleIcon />
								</PrimaryButton>
							</Box>
							<Box mr={1}>
								<PrimaryButton size="small" onClick={() => setShowReportModal(true)}>
									<SummarizeIcon />
								</PrimaryButton>
							</Box>
							<Box mr={1}>
								<Link
									href={routes.missions.excel(
										{ id: +id },
										{ filters: mission.tricoCode, periodStart, periodEnd },
									)}
								>
									<PrimaryButton size="small" style={{ padding: '6px 0px' }}>
										<FaFileExcel fontSize={20} />
									</PrimaryButton>
								</Link>
							</Box>
							<Box mr={1}>
								<PrimaryButton size="small" onClick={() => setShowMessageModal(true)}>
									<SendIcon />
								</PrimaryButton>
							</Box>
							<Box display="flex" mr={1}>
								<RouterLink to={`/missions/modifier/${id}`}>
									<PrimaryButton size="small">
										<Edit />
									</PrimaryButton>
								</RouterLink>
							</Box>
							<Box>
								<DeleteButton
									size="small"
									url={routes.missions.delete({ id: +id })}
									confirmationMessage="Êtes vous sûr de vouloir supprimer la mission ?"
									successMessage="La mission a bien été supprimée !"
									returnLabel="Retour aux missions"
									returnUrl={`/missions`}
								/>
							</Box>
						</Box>
					</Box>
					<Box
						display="flex"
						style={{ gap: '1%' }}
						mx={-1}
						padding="8px"
						width={'60%'}
						minWidth={'500px'}
					>
						<DatePicker
							format="DD/MM/YYYY"
							label="Début de la période"
							value={periodStart}
							onChange={(date) => setPeriodStart(date)}
						/>
						<DatePicker
							format="DD/MM/YYYY"
							label="Fin de la période"
							value={periodEnd}
							onChange={(date) => setPeriodEnd(date)}
						/>
						<FilterWeeks
							setPeriodStart={setPeriodStart}
							startDate={mission.startDate}
							endDate={mission.endDate}
							setPeriodEnd={setPeriodEnd}
						/>
					</Box>
					<Title>
						Mission <b>{mission.tricoCode}</b> du{' '}
						<b>{moment(mission.startDate).format('DD/MM/YYYY')}</b> au{' '}
						<b>{moment(mission.endDate).format('DD/MM/YYYY')}</b>
					</Title>

					<Box my={2}>
						<InputLabel>Association</InputLabel>
						<Box my={1} marginBottom={2}>
							{logo?.logo ? (
								<Box
									width={'120px'}
									height={'120px'}
									style={{
										backgroundSize: 'cover',
										backgroundImage: `url(${logo.logo})`,
										cursor: 'pointer',
									}}
									onClick={() => (window.location.href = `/associations/${mission.associationId}`)}
								/>
							) : (
								<Box>Pas de logo</Box>
							)}
							<Link component={RouterLink} to={`/associations/${mission.associationId}`}>
								{mission.association?.name}
							</Link>
						</Box>
						<InputLabel>Responsable opérationnel terrain</InputLabel>
						<Box my={1}>
							{mission.programManagerId ? (
								<Link component={RouterLink} to={`/ressources-humaines/${mission.programManagerId}`}>
									{mission.programManager.firstName} {mission.programManager.lastName}
								</Link>
							) : (
								'Aucun'
							)}
						</Box>
						<InputLabel>Responsable d'équipe</InputLabel>
						<Box my={1}>
							{mission.teamManagerId ? (
								<Link component={RouterLink} to={`/ressources-humaines/${mission.teamManagerId}`}>
									{mission.teamManager.firstName} {mission.teamManager.lastName}
								</Link>
							) : (
								'Aucun'
							)}
						</Box>
						<InputLabel>Rythme</InputLabel>
						<Box my={1}>{mission.rhythm === 'lv' ? 'Lundi-Vendredi' : 'Mardi-Samedi'}</Box>
					</Box>
				</Box>
			)}

			{/* SEND MESSAGE MODAL */}
			{showMessageModal && (
				<Modal open onClose={() => setShowMessageModal(false)}>
					<Box p={2}>
						<MessageForm missionId={id} onClose={() => setShowMessageModal(false)} />
					</Box>
				</Modal>
			)}

			{/* SEND REPORT MODAL */}
			{showReportModal && (
				<Modal open onClose={() => setShowReportModal(false)}>
					<Box p={2}>
						<ReportForm
							missionId={id}
							onClose={() => {
								setShowReportModal(false);
								window.location.reload();
							}}
						/>
					</Box>
				</Modal>
			)}

			{/* SEND GOALS MODAL */}
			{showGoalsModal && (
				<Modal open onClose={() => setShowGoalsModal(false)}>
					<Box p={2}>
						<GoalsForm
							missionId={+id}
							onClose={() => {
								setShowGoalsModal(false);
								window.location.reload();
							}}
						/>
					</Box>
				</Modal>
			)}
			<DashboardGoals missionId={+id} mission={mission} filters={filters} />
			<Title>Tableau de bord</Title>
			<CardContainer>
				<Dashboard1 filters={filters} />
			</CardContainer>
			<Box display="flex" justifyContent="end" mb={2}>
				<PrimaryButton onClick={() => setDashboard2(!openDashboard2)}>
					{openDashboard2 ? '-' : '+'}
				</PrimaryButton>
			</Box>
			{openDashboard2 && (
				<CardContainer>
					<Dashboard2 filters={filters} user={null} />
				</CardContainer>
			)}

			{/* CHARTS */}
			<div>
				<MissionOverviewChart missionId={id} missionTricoCode={mission?.tricoCode} />
			</div>
			<Box style={{ position: 'relative', width: '100%' }}>
				<Box display="flex" flexWrap="wrap" width="100%" style={{ margin: -5 }}>
					<Paper variant="outlined" className={classes.paper}>
						<Title>Répartition des âges</Title>
						<Box width="80vw" maxWidth={400} boxSizing="border-box" flexGrow={1}>
							<AgeStats missionId={id} periodStart={periodStart} periodEnd={periodEnd} />
						</Box>
					</Paper>
					<Paper variant="outlined" className={classes.paper}>
						<Title>Production horaire</Title>
						<Box width="80vw" maxWidth={400} display="flex" flexGrow={1} alignItems="center">
							<HourlyStats missionId={id} />
						</Box>
					</Paper>
				</Box>
			</Box>

			<Box display="flex" alignItems="flex-end" gridGap={16}>
				<Title mt={3}>Récapitulatif recruteurs donateurs</Title>
				<Link href={`${routes.missions.excelCompo}?missionId=${id}`}>
					<Button className={classes.excelButton} size="small" variant="contained" color="primary">
						<FaFileExcel size={16} />
					</Button>
				</Link>
			</Box>
			<MissionRecruitersTable users={users} setUsers={setUsers} areUsersLoading={areUsersLoading} />

			<Box>
				<Title mt={3}>Commentaires de mission</Title>
				<Box display="flex" justifyContent="space-between" alignItems="end">
					<MissionComments
						midMissionComment={reports?.mid?.comment}
						endMissionComment={reports?.end?.comment}
					/>
				</Box>
			</Box>

			<CardContainer display="flex" paddingRight={'8px'}>
				<LazyLoadComponent>
					{mission && <MissionDatas mission={mission} user={null} filters={filters} userId={null} />}
				</LazyLoadComponent>
			</CardContainer>

			<Title mt={3}>Autorisations de la mairie</Title>
			<Box my={2}>
				<Box my={1}>
					{!authsAreLoading &&
						authorizations.map((auth) => (
							<Box display="flex" alignItems="center" my={2} mt={1} key={auth.id}>
								<Link href={`${auth.path}`} target="_blank">
									{auth.name}
								</Link>
								<Link
									style={{ cursor: 'pointer', marginLeft: 5 }}
									onClick={() => deleteAuthorization(auth.id + '')}
								>
									<RemoveCircle style={{ color: '#C63', fontSize: 16, marginTop: 4 }} />
								</Link>
							</Box>
						))}
					<Box display="flex" alignItems="center" my={2} mt={1}>
						<Link onClick={() => setFilePickerOpen(true)} style={{ cursor: 'pointer' }}>
							<Box display="inline-flex" alignItems="center" my={2} mt={1}>
								<AddCircle />
								<Box mx={1}>Télécharger une autorisation</Box>
							</Box>
						</Link>
					</Box>
					<FileModalPicker
						open={filePickerOpen}
						onChange={addAuthorization}
						onClose={() => setFilePickerOpen(false)}
						category="town_hall_authorizations"
						accept="application/pdf"
					/>
				</Box>
			</Box>

			<Previsions />
		</Box>
	);
};

const useStyles = makeStyles({
	fields: {
		marginTop: '20px',
		marginBottom: '20px',
		display: 'block',
		width: '100%',
		boxSizing: 'border-box',
		'& > *': {
			display: 'block',
			width: '100%',
			marginTop: '20px',
			flexGrow: 1,
		},
		'& > *:first-child': {
			margin: '0px !important',
		},
	},
	paper: {
		margin: 5,
		padding: '20px 10px',
		flexGrow: 1,
		display: 'flex',
		alignItems: 'center',
		flexFlow: 'column',
		boxSizing: 'border-box',
	},
	excelButton: {
		padding: '8px 8px',
		minWidth: 10,
		marginRight: 5,
	},
});

export default MissionDetails;
