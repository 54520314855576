import React from "react";
import Title from "../Title";
import { Box, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import useFetch from "../../hooks/useFetch";
import routes from "../../api/routes";
import { useHistory } from "react-router-dom";

export type AbsenceDetails = {
	userId: string,
	absences: number,
	justifiedAbsences: number,
	unjustifiedAbsences: number,
	sickLeaves: number,
	discharges: number,
	name: string,
};

const AbsentDetailsModal = ({ onClose, missionId, recruiterId }: { recruiterId?: string, onClose: () => any, missionId: string }) => {
	const [, absences] = useFetch<AbsenceDetails[]>(
		routes.missions.getAbsencesDetails({
			id: missionId
		}),
	);
	const history = useHistory();
	const tableClasses = useStyles({ border: false });

	return <Box>
		<Title mb={2}>Détail des absences</Title>
		<Paper variant="outlined">
			<Table size="medium" classes={{ root: tableClasses.tableRoot }}>
				<TableHead>
					<TableRow>
						<TableCell>Nom</TableCell>
						<TableCell>Total</TableCell>
						<TableCell>ABS Justifiées</TableCell>
						<TableCell>ABS Injustifiées</TableCell>
						<TableCell>Arrêts Maladie</TableCell>
						<TableCell>Décharges</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{absences && absences.map((row, i) => (
						<React.Fragment key={row.userId}>
							<TableRow 
								onClick={() =>history.push(`/missions/${missionId}/recruteur/${row.userId}`)}
								style={{cursor: 'pointer'}}
							>
								<TableCell align="left">{row.name}</TableCell>
								<TableCell align="left">{row.absences}</TableCell>
								<TableCell align="left">{row.justifiedAbsences}</TableCell>
								<TableCell align="left">{row.unjustifiedAbsences}</TableCell>
								<TableCell align="left">{row.sickLeaves}</TableCell>
								<TableCell align="left">{row.discharges}</TableCell>
							</TableRow>
						</React.Fragment>
					))}
				</TableBody>
			</Table>
		</Paper>
		</Box>
}

const useStyles = makeStyles(({ isBigScreen }: { isBigScreen?: boolean }) => ({
	tableRoot: {
		whiteSpace: 'nowrap',
		'& thead th': {
			textTransform: 'uppercase',
			color: '#444',
			fontSize: 11.5,
		},
		'& tbody tr.noBottomBorder > td': {
			borderBottom: 'none',
		},
		'& tbody tr:not(.comment):hover': {
			backgroundColor: '#FAFAFA',
			boxShadow: 'inset 3px 0px 0px 0px #0093EE',
		},
	},
}));

export default AbsentDetailsModal;